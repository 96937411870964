<style scoped>
/* remove underline from text fields */
.no-underline>>>.v-input__slot::before,
.no-underline>>>.v-input__slot::after {
	border-bottom: none !important;
	border-color: white !important;
}

.no-underline>>>.v-input__slot {
	font-size: 14px !important;
}
</style>
<template>
	<v-card outlined rounded>
		<v-card-title>
			<span>{{ $t("ShipmentCard.Levernota") }} {{ shipment.levernotanr }}</span>
			<v-spacer />
			<v-btn plain @click.stop="previewDocument">
				<v-icon>mdi-file-document-outline</v-icon>
				<span>pdf</span>
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-row justify="start">
				<v-col cols="auto">
					<v-text-field class="no-underline" :label="$t('Date')" :value="$d(new Date(shipment.datum), 'short')"
						readonly />
				</v-col>
				<v-col cols="auto">
					<v-text-field class="no-underline" :label="$t('Net_total')"
						:value="$n(shipment.netto, 'decimal') + ' ' + shipment.muntcode" readonly />
				</v-col>
				<v-spacer />
				<v-col cols="auto">
					<v-chip label outlined small color="info">{{ $t('ShipmentCard.Shipment_status.' + shipment.lstatus)
					}}</v-chip>
				</v-col>

			</v-row>
			<v-sheet outlined>
				<v-data-table disable-sort disable-pagination hide-default-footer :items="shipment.shipmentLine"
					:headers="shipmentLineHeaders" dense height="175" fixed-header>
					<template v-slot:[`item.ehp`]="{ item }">
						{{ $n(item.ehp, "decimal") }}
					</template>
					<template v-slot:[`item.hkort`]="{ item }">
						<span v-if="item.hkort">
									{{ $n(item.hkort * -0.01, "percent") }}
								</span>
					</template>
				</v-data-table>
			</v-sheet>
		</v-card-text>
	</v-card>
</template>

<script>
import caas from "@/services/caas";
export default {
	name: "ShipmentCard",
	props: {
		shipment: Object,
	},
	data() {
		return {
			shipmentLineHeaders: [
				{ text: this.$t("ShipmentCard.Order"), value: "ordernr" },
				{ text: this.$t("ShipmentCard.Code"), value: "anr" },
				{
					text: this.$t("ShipmentCard.Omschrijving"),
					value: "omschrijving",
					align: "start",
					width: "40%",
				},
				{ text: this.$t("ShipmentCard.Aantal"), value: "aantal", align: "end" },
				{
					text: this.$t("ShipmentCard.Eenh_prijs"),
					value: "ehp",
					align: "end",
				},
				{ text: "", value: "hkort", align: "end" },
				{ text: this.$t("ShipmentCard.BTW"), value: "btw", align: "end" },
			],
		};
	},
	methods: {
		previewDocument() {
			let params = {
				documentId: this.shipment.levernotanr,
				documentType: "LNP",
			};
			caas.rpc("getDocumentUrl", params).then((response) => {
				window.open(response.data.success.documentUrl);
			});
		},
	},
};
</script>

