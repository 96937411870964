<template>
	<v-container fluid>
		<v-row class="primary sticky-top">
			<v-col cols="12">
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ $t('SmartNavBar.My_shipments') }}</v-toolbar-title>
					<v-toolbar-items class="kmp">
						<v-tabs v-model="tabId" class="my-auto" background-color="transparent" @change="onTabChanged"
							hide-slider show-arrows>
							<v-tab>{{ $t('Shipments.All_shipments') }}</v-tab>
							<v-tab>{{ $t('Shipments.Open_shipments') }}</v-tab>
						</v-tabs>
					</v-toolbar-items>
				</v-toolbar>
			</v-col>
			<v-progress-linear v-if="loading" active indeterminate rounded color="white"></v-progress-linear>
		</v-row>
		<v-row justify="center" v-for="shipment of shipmentList" :key="shipment.shipmentId">
			<v-col cols="12" md="9" lg="7">
				<ShipmentCard :shipment="shipment" />
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-btn class="my-3" v-if="this.rowIdent" :loading="loading" color="primary" @click="getShipmentList()">
				{{ $t('Load_more') }}
			</v-btn>
		</v-row>
	</v-container>
</template>

<script>
import caas from "@/services/caas";
import ShipmentCard from "../components/ShipmentCard";
export default {
	name: "Shipments",
	components: { ShipmentCard },
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + 0 + "px",
				zIndex: 5,
			};
		},
	},
	data() {
		return {
			tabId: 0,
			loading: false,
			shipmentList: [],
			rowsToBatch: 10,
			rowNum: 0,
			rowIdent: "",
		};
	},
	methods: {
		getShipmentList() {
			this.loading = true;
			let params = {
				statusFilter: this.tabId,
				rowNum: this.rowNum,
				rowIdent: this.rowIdent,
				rowsToBatch: this.rowsToBatch,
			};
			return caas
				.rpc("getPortalUserShipments", params)
				.then((response) => {
					this.loading = false;
					if (response.data.success.data) {
						this.shipmentList.push(
							...response.data.success.data.shipmentView.shipment
						);
						this.rowNum = response.data.success.rowNum;
						this.rowIdent = response.data.success.rowIdent;
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
		onTabChanged() {
			this.shipmentList = [];
			this.rowNum = 0;
			this.rowIdent = "";
			this.getShipmentList();
		},
	},
	mounted() {
		this.getShipmentList();
	},
};
</script>
